var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full flex flex-col items-center justify-center lg:gap-y-4",
    },
    [
      _c(
        "h1",
        {
          staticClass:
            "w-full lg:text-center px-4 lg:px-6 pt-2.5 pb-2 text-lg lg:text-2xl text-primary uppercase font-bold tracking-wider",
        },
        [_vm._v(" Tokens History ")]
      ),
      _c("div", { staticClass: "w-full lg:w-11/12 flex-1 min-h-0 lg:h-5/6" }, [
        _c(
          "div",
          {
            staticClass:
              "grid-container lg:rounded-xl overflow-hidden h-full flex flex-col",
          },
          [
            _c(
              "header",
              {
                staticClass:
                  "grid grid-cols-4 sm:grid-cols-6 px-4 lg:px-6 pt-3 pb-2 lg:text-xl gradient-blue-30 font-semibold tracking-wide uppercase",
              },
              [
                _c("h2", { staticClass: "col-span-2 sm:col-span-3" }, [
                  _vm._v("Descrizione"),
                ]),
                _c("h2", { staticClass: "text-center" }, [_vm._v("Tipo")]),
                _vm.minWidth560
                  ? _c("h2", { staticClass: "text-center mr-12" }, [
                      _vm._v("Valore"),
                    ])
                  : _vm._e(),
                _c("h2", {}, [_vm._v("Data")]),
              ]
            ),
            _c(
              "body",
              { staticClass: "flex-1 overflow-y-auto space-y-2" },
              _vm._l(_vm.movements, function (mov, n) {
                return _c(
                  "div",
                  {
                    key: mov.id,
                    staticClass:
                      "grid grid-cols-4 sm:grid-cols-6 px-4 lg:px-6 pt-1.5 pb-1 text-xs lg:text-sm",
                    class: n % 2 === 0 ? "bg-gray-700" : "bg-gray-600",
                  },
                  [
                    !_vm.minWidth560 && mov.value
                      ? _c("p", { staticClass: "col-span-2 sm:col-span-3" }, [
                          _vm._v(" " + _vm._s(mov.description) + " ( "),
                          _c(
                            "span",
                            {
                              staticClass: "font-semibold -mx-0.5 text-xs",
                              class: _vm.isNumberNegative(mov.value)
                                ? "text-red"
                                : "text-green",
                            },
                            [_vm._v(" " + _vm._s(mov.value) + " ")]
                          ),
                          _vm._v(" ) "),
                        ])
                      : _c(
                          "p",
                          {
                            staticClass:
                              "col-span-2 sm:col-span-3 font-semibold text-xs lg:text-sm",
                          },
                          [_vm._v(" " + _vm._s(mov.description) + " ")]
                        ),
                    _c("p", { staticClass: "capitalize text-center" }, [
                      _vm._v(" " + _vm._s(mov.movementType.name) + " "),
                    ]),
                    _vm.minWidth560
                      ? _c(
                          "p",
                          {
                            staticClass: "font-semibold text-center mr-12",
                            class: _vm.isNumberNegative(mov.value)
                              ? "text-red"
                              : "text-green",
                          },
                          [_vm._v(" " + _vm._s(mov.value) + " ")]
                        )
                      : _vm._e(),
                    _c("p", {}, [
                      _vm._v(_vm._s(_vm._f("dateTime")(mov.createdAt))),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }