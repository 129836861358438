<template>
  <div
    class="w-full h-full flex flex-col items-center justify-center lg:gap-y-4"
  >
    <h1
      class="w-full lg:text-center px-4 lg:px-6 pt-2.5 pb-2 text-lg lg:text-2xl text-primary uppercase font-bold tracking-wider"
    >
      Tokens History
    </h1>

    <div class="w-full lg:w-11/12 flex-1 min-h-0 lg:h-5/6">
      <div
        class="grid-container lg:rounded-xl overflow-hidden h-full flex flex-col"
      >
        <header
          class="grid grid-cols-4 sm:grid-cols-6 px-4 lg:px-6 pt-3 pb-2 lg:text-xl gradient-blue-30 font-semibold tracking-wide uppercase"
        >
          <h2 class="col-span-2 sm:col-span-3">Descrizione</h2>
          <!-- <div class="flex col-span-2"> -->
          <h2 class="text-center">Tipo</h2>
          <h2 v-if="minWidth560" class="text-center mr-12">Valore</h2>
          <!-- </div> -->
          <h2 class="">Data</h2>
        </header>
        <body class="flex-1 overflow-y-auto space-y-2">
          <div
            v-for="(mov, n) in movements"
            :key="mov.id"
            class="grid grid-cols-4 sm:grid-cols-6 px-4 lg:px-6 pt-1.5 pb-1 text-xs lg:text-sm"
            :class="n % 2 === 0 ? 'bg-gray-700' : 'bg-gray-600'"
          >
            <p
              v-if="!minWidth560 && mov.value"
              class="col-span-2 sm:col-span-3"
            >
              {{ mov.description }} (
              <span
                class="font-semibold -mx-0.5 text-xs"
                :class="isNumberNegative(mov.value) ? 'text-red' : 'text-green'"
              >
                {{ mov.value }}
              </span>
              )
            </p>
            <p
              v-else
              class="col-span-2 sm:col-span-3 font-semibold text-xs lg:text-sm"
            >
              {{ mov.description }}
            </p>
            <!-- <div class="flex col-span-2"> -->
            <p class="capitalize text-center">
              {{ mov.movementType.name }}
            </p>
            <p
              v-if="minWidth560"
              class="font-semibold text-center mr-12"
              :class="isNumberNegative(mov.value) ? 'text-red' : 'text-green'"
            >
              {{ mov.value }}
            </p>
            <!-- </div> -->
            <p class="">{{ mov.createdAt | dateTime }}</p>
          </div>
        </body>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllTokensMovements } from "@/api/tokensRepository.js";
import minWidthMixin560 from "@/mixins/minWidthMixin560.vue";

export default {
  name: "TokensMovements",

  mixins: [minWidthMixin560],

  data() {
    return {
      movements: null,
    };
  },

  async created() {
    try {
      const res = await getAllTokensMovements(
        this.$store.state.userInfo.user.id
      );
      this.movements = res.data;
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    isNumberNegative(nr) {
      if (Math.sign(nr) === -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.gradient-blue-30 {
  background: -moz-linear-gradient(30deg, #0e0d4b 25%, #26c6da 75%);
  background: -webkit-gradient(
    linear,
    30deg,
    color-stop(35%, #0e0d4b),
    color-stop(100%, #26c6da)
  );
  background: linear-gradient(30deg, #0e0d4b 35%, #26c6da 100%);
}

.text-red {
  color: #ff0000;
}

.text-green {
  color: #3ab336;
}
</style>
